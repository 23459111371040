exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-founder-js": () => import("./../../../src/pages/founder.js" /* webpackChunkName: "component---src-pages-founder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-article-1-article-detail-js": () => import("./../../../src/templates/Article1/article-detail.js" /* webpackChunkName: "component---src-templates-article-1-article-detail-js" */),
  "component---src-templates-article-1-article-list-js": () => import("./../../../src/templates/Article1/article-list.js" /* webpackChunkName: "component---src-templates-article-1-article-list-js" */)
}

